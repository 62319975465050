<template>
  <CyTooltip
    :left="$attrs.left || useDefaultTooltipPosition"
    :top="$attrs.top"
    :right="$attrs.right"
    :bottom="$attrs.bottom"
    :disabled="onlyShowForModules && _.isEmpty(resource._modules)">
    <template #activator="{ on: typeTooltip }">
      <span
        class="resource-type"
        v-on="typeTooltip">
        <span
          v-if="resource._modules"
          v-html="`${resource._modules.join('.')}.`"/><b>{{ resource._type }}</b>
      </span>
    </template>
    <span>{{ resource._typeKey }}</span>
  </CyTooltip>
</template>

<script>
export default {
  name: 'CyInfraViewResourceType',
  props: {
    resource: {
      type: Object,
      required: true,
    },
    onlyShowForModules: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    useDefaultTooltipPosition () {
      const { left, top, right, bottom } = this.$attrs
      return _.every([left, top, right, bottom], _.isEmpty)
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-type {
  display: flex;
  flex: 1 0 auto;
  min-width: 0;
  min-height: 18px;
  margin-top: -4px;
  color: cy-get-color("grey", "dark-1");
  font-size: 0.9rem;
  font-weight: $font-weight-default;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
