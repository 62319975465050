<template>
  <div :class="['rappid', 'infraview-diagram', { 'rappid--hide-toolbar': hideToolbar }]">
    <div class="cy-paper">
      <div
        v-if="!loading && hasWarningMessage"
        class="infraview-warning-message">
        <template v-if="showMessage.hasNoExternalBackend">
          <span
            class="pb-2">
            {{ $t('infraviewWarning.noExternalBackend') }}
          </span>
          <CyButton
            class="ma-1"
            :disabled="!$cycloid.permissions.canDisplay('CreateExternalBackend')"
            :to="{
              name: 'newProjectConfigInfraView',
              params: { backRouteTo: $route.name },
            }">
            {{ $t('externalBackends.addConfiguration') }}
          </CyButton>
        </template>
        <span
          v-else-if="showMessage.problem"
          class="pb-2">
          {{ $t('infraviewWarning.problem') }}
        </span>
      </div>

      <CyInfraViewList
        v-show="mode === 'list'"
        class="infraview-diagram__list-view"/>

      <CyInfraViewOld
        v-show="mode === 'oldVersion'"
        v-has-rights-to="['GetProjInfrastructure', projectCanonical]"
        class="infraview-diagram__old-view"/>
    </div>

    <CyInfraViewPopover
      v-show="!_.isEmpty(popoverResource)"
      ref="popover"
      :resource="popoverResource"
      @open-right-panel="openRightPanel"/>

    <CyInfraViewRightPanel
      v-if="!_.isEmpty(rightPanel.element)"
      :key="rightPanel.element._canonical"
      :element="rightPanel.element"
      @center-element="centerElement(rightPanel.cell)"
      @close-right-panel="closeRightPanel"/>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-properties */
import { mapState, mapGetters } from 'vuex'
import CyInfraViewList from '@/components/infra-view/list.vue'
import CyInfraViewOld from '@/components/infra-view/old.vue'
import CyInfraViewPopover from '@/components/infra-view/popover.vue'
import CyInfraViewRightPanel from '@/components/infra-view/right-panel.vue'
import {
  centerElement,
  clearCurrentCell,
  highlightCell,
  highlighterOptions,
  setCurrentCell,
  unhighlightAll,
} from '@/utils/helpers/rappid'

export default {
  name: 'CyInfraViewDiagram',
  components: {
    CyInfraViewList,
    CyInfraViewOld,
    CyInfraViewPopover,
    CyInfraViewRightPanel,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    graph: null,
    paper: null,
    paperScroller: null,
    toolbar: null,
    halo: null,
    commandManager: null,
    element: null,
    rightPanel: {
      cell: null,
      element: null,
    },
    currentCell: null,
    popoverResource: {},
  }),
  computed: {
    ...mapState('organization/project/infraview', {
      diagram: (state) => state.diagram,
      mode: (state) => state.mode,
    }),
    ...mapGetters('organization/project', [
      'projectCanonical',
    ]),
    ...mapGetters('organization/project/infraview', [
      'hasDiagram',
      'hasInvalidExternalBackend',
      'hasNoExternalBackend',
    ]),
    generate () {
      return this.$rappidHelpers.generate(this)
    },
    hasWarningMessage () {
      return _.some(_.values(this.showMessage))
    },
    showMessage () {
      const { loading, hasInvalidExternalBackend, hasNoExternalBackend, diagram } = this
      return {
        hasNoExternalBackend: !loading && hasNoExternalBackend,
        problem: !loading && (hasInvalidExternalBackend || _.isEmpty(diagram?.cells)),
      }
    },
    hideToolbar () {
      return _.some([this.loading, this.mode !== 'diagram', !this.hasDiagram])
    },
  },
  watch: {
    diagram: {
      handler () {
        this.setup()
        this.$emit('stop-loading')
      },
      immediate: true,
    },
  },
  created () {
    this.getCellType = this.$rappidHelpers.getCellType
  },
  methods: {
    setup () {
      if (!this.hasDiagram) return
      this.graph = this.generate.graph()
      this.$rappidHelpers.defineCustomLinks(this)
      this.paper = this.generate.paper()
      this.commandManager = this.generate.commandManager()
      this.toolbar = this.generate.toolbar()
      this.tooltips = this.generate.tooltips()
      this.$rappidHelpers.defineCustomShapes(this)
      this.loadDiagram()
      this.setupPopover()
    },
    loadDiagram () {
      if (this.graph) this.graph.fromJSON(this.diagram)
      if (this.hasAnyNodesMissingPositions()) {
        this.$joint.layout?.DirectedGraph?.layout(this.graph, {
          setLinkVertices: true,
          rankDir: 'TB',
          marginX: 200,
          marginY: 200,
        })
      }
    },
    setupPopover () {
      this.paperScroller?.$el?.[0]?.addEventListener('scroll', this.handlePopupClose)
    },
    hasAnyNodesMissingPositions () {
      return _.get(this.diagram, 'cells', [])
        .filter((cell) => _.has(cell, 'position'))
        .some(({ position: { x, y } }) => _.includes([x, y], 0))
    },
    openRightPanel (cellView) {
      this.unhighlightAll()

      this.rightPanel.element = cellView
        ? this.getConfigElement(cellView)
        : this.popoverResource

      this.rightPanel.cell = this.currentCell
      this.currentCell.highlight(null, this.highlighterOptions(this.currentCell))
      this.handlePopupClose()
    },
    getConfigElement (cellView) {
      const { name, typeKey } = _.get(cellView, 'model.attributes.attrs', cellView.attrs)
      return this.diagram.config.resource[typeKey][name]
    },
    async handleResourceMouseEnter ({ model: { id }, $el }) {
      const cellElement = _.find(this.diagram.cells, ({ id: cellId }) => id === cellId)

      if (!cellElement || cellElement.type !== 'custom.Resource') return

      this.$set(this, 'popoverResource', this.getConfigElement(cellElement))
      this.setCurrentCell(this.paper?.findViewByModel(id))
      await this.$nextTick()

      new this.$joint.ui.Popup({
        content: this.$refs.popover?.$el,
        target: $el[0],
        autoClose: true,
      }).render()

      // tiny hack needed to make sure our joint-popup is not attached to <html>
      // and thus not having its own z-index position, overlapping the right panel
      const $jointPopupEl = document.getElementsByClassName('joint-popup')[0]
      const $appEl = document.getElementsByClassName('v-application--wrap')[0]
      $appEl.appendChild($jointPopupEl)

      // reposition the popup (top, bottom, right, left) according to resource position in the diagrma
      this.repositionPopup($jointPopupEl, $el)
    },
    handlePopupClose () {
      this.$joint.ui.Popup.close()
      this.popoverResource = {}
    },
    closeRightPanel () {
      this.rightPanel.element = null
      this.rightPanel.cell = null
      this.clearCurrentCell()
    },
    // makes so the popover does not go out of boundaries
    // also make sure the upper arrow points exactly at the resource X coordinates, relative to viewport width
    // when popup element goes out of left boundary
    repositionPopup ($popupEl, $targetEl) {
      const { x: elX, y: elY } = $targetEl[0].getBoundingClientRect()
      if (elX < 230) {
        $popupEl.style.left = '100px'
        $popupEl.style.setProperty('--joint-popup-arrow-x-position', `${_.max([elX - 60, 15])}px`)
      }
      // when popup element goes out of right boundary
      if (elX + 190 > window.innerWidth) {
        $popupEl.style.left = `${window.innerWidth - 340}px`
        $popupEl.style.setProperty('--joint-popup-arrow-x-position', `${elX + 30 - (window.innerWidth - 340)}px`)
      }
      // when popup element goes out of bottom boundary
      if (elY - 60 > window.innerHeight / 2) {
        $popupEl.style.top = `${elY - $popupEl.clientHeight - 30}px`
        $popupEl.style.setProperty('--joint-popup-arrow-y-position', `${$popupEl.clientHeight}px`)
        $popupEl.style.setProperty('--joint-popup-arrow-y-shadow-offset', '3px')
        $popupEl.style.setProperty('--joint-popup-rotate', '180deg')
      } else {
        $popupEl.style.setProperty('--joint-popup-arrow-y-position', '-22px')
        $popupEl.style.setProperty('--joint-popup-arrow-y-shadow-offset', '-3px')
        $popupEl.style.setProperty('--joint-popup-rotate', '0deg')
      }
    },
    centerElement,
    clearCurrentCell,
    highlightCell,
    highlighterOptions,
    unhighlightAll,
    setCurrentCell,
  },
  i18n: {
    messages: {
      en: {
        fit: 'fit to canvas',
        infraviewWarning: {
          noExternalBackend: 'Please configure the Terraform state backend for this environment',
          problem: 'There was a problem loading your Terraform state',
        },
      },
      es: {
        fit: 'adaptar a la lona',
        infraviewWarning: {
          noExternalBackend: 'Configure el servidor de Terraform state para este entorno.',
          problem: 'Hubo un problema al cargar su estado de Terraform',
        },
      },
      fr: {
        fit: 'adapter à la toile',
        infraviewWarning: {
          noExternalBackend: 'Veuillez configurer le backend Terraform state pour cet environnement',
          problem: `Un problème s'est produit lors du chargement de vos données Terraform`,
        },
      },
    },
  },
}
</script>

<style lang="scss"> /* FIXME: scope!! */
.joint-popup {
  --joint-popup-arrow-x-position: 50%;
  --joint-popup-arrow-y-position: -22px;
  --joint-popup-arrow-y-shadow-offset: 3px;
  --joint-popup-rotate: 180deg;

  &::after {
    top: calc(var(--joint-popup-arrow-y-position) + var(--joint-popup-arrow-y-shadow-offset)) !important;
    left: var(--joint-popup-arrow-x-position);
    transform: rotate(var(--joint-popup-rotate)) !important;
  }

  &::before {
    top: var(--joint-popup-arrow-y-position) !important;
    left: var(--joint-popup-arrow-x-position);
    transform: rotate(var(--joint-popup-rotate)) !important;
  }

  &.theme-default {
    z-index: 10;
  }
}
</style>

<style lang="scss" scoped>
.infraview-diagram {
  position: relative;
  flex: 1 1 auto;

  &__list-view,
  &__old-view {
    display: flex;
    position: absolute;
    z-index: 100;
    top: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__old-view {
    background: cy-get-color("background");
  }
}

.rappid {
  width: 100%;
  height: calc(100% - 90px);
  background: cy-get-color("grey", "light-3");

  .cy-paper {
    position: relative;
    height: 100%;
  }

  &--hide-toolbar {
    ::v-deep .joint-toolbar {
      display: none;
    }
  }

  ::v-deep .joint-element {
    cursor: pointer;
  }

  ::v-deep .joint-paper-scroller {
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    overflow: auto;
    line-height: 0;

    .joint-paper {
      top: 0 !important;
      left: 0 !important;
    }
  }

  .infraview-warning-message {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: cy-get-color("grey", "dark-3", 0.5);
    color: cy-get-color("primary");
    font-size: $font-size-h3;
    user-select: none;
  }
}
</style>
