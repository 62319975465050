var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rappid-popover"},[_c('div',{staticClass:"rappid-popover__header"},[_c('h4',{staticClass:"title-row font-weight-bold"},[_c('span',{staticClass:"resource-wrapper"},[_c('span',{staticClass:"resource-name"},[_vm._v(" "+_vm._s(_vm.nodeName || _vm.resource._name)+" ")]),(_vm.status)?_c('v-chip',{class:['resource-status', 'font-weight-normal', {
            'v-chip--success': _vm.status === 'running',
            'v-chip--warning': _vm.status === 'pending',
            'v-chip--error': _vm.status === 'stopping',
            'v-chip--default': _vm.status === 'unknown',
          }],attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm._.upperFirst(_vm.status))+" ")]):_vm._e()],1)]),_c('CyInfraViewResourceType',{attrs:{"resource":_vm.resource,"bottom":""}})],1),_c('div',{staticClass:"rappid-popover__fields my-2"},_vm._l((_vm.mappedFields),function(ref,index){
          var key = ref.key;
          var value = ref.value;
return _c('CyInfraViewProperty',{key:(value + "-" + key + "-field-" + index),attrs:{"property":{ key: key, value: value }}})}),1),_c('a',{staticClass:"cy-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('open-right-panel')}}},[_vm._v(" "+_vm._s(_vm.$t('seeDetails' ))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }