<template>
  <div class="infraview__list">
    <div class="search-area d-flex justify-center mt-4">
      <v-text-field
        v-model="searchTerm"
        :label="$t('forms.fieldSearch')"
        class="search-area__search search-field mx-1"
        append-icon="search"/>
      <v-select
        v-model="selectedResourceType"
        :items="resourceTypes"
        class="search-area__filters search-field mx-1"
        :label="$t('forms.type')"/>
    </div>

    <div class="resources-list cy-scrollbars justify-center mt-5 px-5">
      <CyInfraViewListResourceType
        v-for="[type, resources] of _.entries(filteredResources)"
        :key="type"
        :resources="resources"
        :type="type"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyInfraViewListResourceType from '@/components/infra-view/list-resource-type.vue'

export const EXCLUDED_PROPERTIES = ['_private', 'available', 'instance_state', 'Schema', 'State', 'tags']

export default {
  name: 'CyInfraViewList',
  components: {
    CyInfraViewListResourceType,
  },
  data: () => ({
    searchTerm: '',
    selectedResourceType: '',
  }),
  computed: {
    ...mapGetters('organization/project/infraview', [
      'allResourceTypes',
      'allResourcesGroupedByType',
    ]),
    filteredResources () {
      const { allResourcesGroupedByType, typeDropdownFilter, searchFilter } = this

      return _(allResourcesGroupedByType)
        .entries()
        .filter(typeDropdownFilter)
        .filter(searchFilter)
        .fromPairs()
        .value()
    },
    resourceTypes () {
      return [this.$t('all'), ...this.allResourceTypes]
    },
  },
  mounted () {
    this.selectedResourceType = this.resourceTypes[0]
  },
  methods: {
    searchFilter ([type]) {
      const { allResourcesGroupedByType, searchTerm } = this

      if (_.isEmpty(searchTerm) || this.matchesSearch(type)) return true

      for (const name in allResourcesGroupedByType[type]) {
        const allowedPropertiesObj = _.entries(allResourcesGroupedByType[type][name])
          .reduce((obj, [key, value]) => (_.includes(EXCLUDED_PROPERTIES, key) ? obj : { [key]: value, ...obj }), {})

        // filter by typed resource name, property names and values
        const terms = [name, ..._.keys(allowedPropertiesObj), ..._.values(allowedPropertiesObj)]
        if (_.some(terms, this.matchesSearch)) return true
      }
      return false
    },
    typeDropdownFilter ([type]) {
      const { selectedResourceType } = this

      return selectedResourceType === this.$t('all')
        ? type !== 'aws_security_group' && type !== 'aws_security_group_rule'
        : type === selectedResourceType
    },
    matchesSearch (str) {
      const { searchTerm } = this
      return _.isString(str) && str.toLowerCase().includes(searchTerm.toLowerCase())
    },
  },
}
</script>

<style lang="scss" scoped>
.infraview__list {
  background-color: cy-get-color("grey", "light-2");

  .search-area .v-input {
    max-width: 280px;
  }
}
</style>
