<template>
  <div>
    <!-- no AWS credentials -->
    <div
      v-if="hasNoAWSCredentials"
      class="text-center mt-6 pa-2">
      <v-avatar
        color="secondary"
        size="72">
        <v-icon
          x-large
          color="white">
          vpn_key
        </v-icon>
      </v-avatar>
      <h2
        v-if="canListCredentials"
        class="mt-4">
        {{ $t('noAwsCredentials') }}
      </h2>
      <h2
        v-else
        class="mt-4">
        {{ $t('cantListCredentials') }}
      </h2>
    </div>

    <!-- has AWS credentials -->
    <v-col v-else>
      <div class="content-header">
        <v-col
          cols="3"
          class="pr-0">
          <v-select
            v-model="credentialCanonical"
            :items="getCredentialsByType('aws')"
            :loading="loading.credentials"
            :label="$t('Credential')"
            class="credentials search-field"
            item-value="canonical"
            item-text="name">
            <template #selection="data">
              <v-avatar
                :key="data.item.name"
                class="ml-2 mr-4"
                size="22">
                <CyIconCredential type="aws"/>
              </v-avatar>
              <div class="black--text">
                {{ data.item.name }}
              </div>
            </template>
            <template #item="data">
              <v-list-item-avatar
                width="30px"
                min-width="30px"
                class="my-0 mr-6">
                <CyIconCredential type="aws"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="9"
          class="pl-4">
          <v-card class="mr-4 search">
            <v-row>
              <v-col
                cols="8"
                md="10">
                <v-text-field
                  v-model="search"
                  :label="$t('forms.fieldSearch')"
                  class="search-field"
                  hide-details
                  append-icon="search"/>
              </v-col>
              <v-col
                cols="4"
                md="2"
                class="pr-0">
                <CyButton
                  :theme="showSettings ? 'accent' : 'secondary'"
                  variant="secondary"
                  class="mt-4"
                  sm
                  @click="showSettings = !showSettings">
                  <v-icon small>
                    {{ showSettings ? 'keyboard_arrow_down': 'settings' }}
                  </v-icon>
                  {{ $t('untranslated.dataset') }}
                </CyButton>
              </v-col>
            </v-row>
            <transition name="fade-height">
              <v-row
                v-if="showSettings"
                class="pa-2 ma-0 mt-1 settings v-card transition"

                @mouseleave="showSettings = false">
                <v-col
                  v-for="field of $static.availableFields"
                  :key="field"
                  cols="12"
                  md="3"
                  lg="2"
                  class="pa-0">
                  <v-checkbox
                    v-model="selectedFields"
                    :label="$t(`infra.${field}`)"
                    :value="field"
                    :ripple="false"
                    color="accent"
                    required
                    class="required-field dataset__checkbox"/>
                </v-col>
              </v-row>
            </transition>
          </v-card>
        </v-col>
      </div>
      <div class="content-body">
        <v-col cols="12">
          <div
            v-if="!loading.credentials && !loading.aggregatedInfra && !aggregatedInfra"
            class="text-center mt-6 pa-2">
            <v-avatar
              class="teal"
              size="72">
              <v-icon
                x-large
                color="white">
                close
              </v-icon>
            </v-avatar>
            <h2 class="mt-4">
              {{ $t('noAwsResourcesForCredential') }}
            </h2>
          </div>
          <v-row
            class="content-body__inner">
            <v-col
              cols="3"
              class="pr-0">
              <div
                v-if="loading.aggregatedInfra && !aggregatedInfra"
                class="text-center">
                <v-progress-circular
                  indeterminate
                  color="secondary"/>
              </div>
              <v-card
                v-if="aggregatedInfra"
                :class="['infra-type-list', {
                  'blur': loading.aggregatedInfra,
                }]">
                <v-list class="infra-type-list__item">
                  <v-list-item
                    v-for="infraType of $static.infraTypes"
                    :key="infraType"
                    :class="['infra-type', {
                      'selected-infra': infraType === selectedInfraType,
                    }]"
                    @click="selectedInfraType = infraType">
                    <v-list-item-avatar class="my-0">
                      <img :src="`/static/images/infra/${ getInfraIcon(infraType) }.svg`">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title :class="{ 'grey--text': !getInfraTypeCounter(infraType) }">
                        <span>{{ $t(infraType) }}</span> ({{ getInfraTypeCounter(infraType) }})
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col
              cols="9"
              class="pl-4 pr-1 pt-0 mt-3 instances-list">
              <div
                v-if="loading.currentInfra"
                class="text-center">
                <v-progress-circular
                  indeterminate
                  color="secondary"/>
              </div>
              <v-data-iterator
                v-if="!loading.currentInfra && filteredItems.length > 0"
                :class="{ 'blur': loading.credentials || !aggregatedInfra }"
                :items="filteredItems"
                :server-items-length="totalItems"
                :options.sync="options">
                <template #item="props">
                  <v-card>
                    <CyInfraViewOldInstance
                      v-if="selectedInfraType === 'instances'"
                      v-model="props.item"
                      :fields="selectedFields"
                      class="mb-2"/>
                  </v-card>
                </template>
              </v-data-iterator>
              <h2
                v-if="hasNoData"
                class="mt-6 text-center">
                {{ $t('noDataFound') }}
              </h2>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import CyInfraViewOldInstance from '@/components/infra-view/old-instance.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyInfraViewOld',
  components: {
    CyInfraViewOldInstance,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('header'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    options: { page: 1, itemsPerPage: 25 },
    totalItems: 0,
    search: '',
    showSettings: false,
    loading: {
      currentInfra: false,
      credentials: false,
      aggregatedInfra: false,
    },
    currentInfra: [],
    aggregatedInfra: null,
    credentialCanonical: null,
    selectedInfraType: null,
    selectedFields: [
      'ImageId',
      'InstanceId',
      'InstanceType',
      'PrivateIpAddress',
    ],
  }),
  computed: {
    ...mapState('organization/project', {
      runningInfra: (state) => state.infraview.runningInfra,
      runningInstances: (state) => state.infraview.runningInstances,
    }),
    ...mapState('organization/project/infraview', {
      mode: (state) => state.mode,
    }),
    ...mapGetters('organization', [
      'hasCredentialsOfType',
      'getCredentialsByType',
    ]),
    $static: () => ({
      defaultTags: [
        'aws:region|eu-west-1',
        'aws:region|eu-west-2',
        'aws:region|eu-west-3',
        'aws:region|eu-central-1',
      ],
      infraTypes: ['instances'],
      availableFields: [
        'ImageId',
        'InstanceId',
        'InstanceType',
        'LaunchTime',
        'SecurityGroups',
        'PrivateIpAddress',
        'PublicDnsName',
        'PublicIpAddress',
        'BlockDeviceMappings',
        'EbsOptimized',
      ],
    }),
    canListCredentials () {
      return this.$cycloid.permissions.canDisplay('ListCredentials')
    },
    filteredItems () {
      const { currentInfra } = this
      if (this.search === '') return currentInfra
      return currentInfra.filter((infra) => !_.isEmpty(this.containedInProperty(infra)))
    },
    hasNoData () {
      return this.aggregatedInfra && !this.loading.currentInfra && _.isEmpty(this.filteredItems)
    },
    hasNoAWSCredentials () {
      return !this.loading.credentials && !this.hasCredentialsOfType('aws')
    },
  },
  watch: {
    options: {
      handler (newVal, oldVal) {
        const hasChanged = !_.isEqual(oldVal.page, newVal.page) || !_.isEqual(oldVal.itemsPerPage, newVal.itemsPerPage)
        if (hasChanged) this.getCurrentInfra()
      },
      deep: true,
    },
    credentialCanonical () {
      this.selectedInfraType = null
      this.getAggregatedInfra()
    },
    selectedInfraType () {
      if (this.selectedInfraType) this.getCurrentInfra()
    },
    mode: {
      async handler (mode) {
        if (mode === 'oldVersion') await this.getAwsCredentials()
      },
      immediate: true,
    },
  },
  async mounted () {
    if (this.selectedInfraType) await this.GET_INFRA(this.selectedInfraType)
  },
  methods: {
    ...mapActions('organization/project/infraview', [
      'GET_INFRA',
      'GET_RUNNING_INFRA',
      'GET_RUNNING_INSTANCES',
    ]),
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    getInfraTypeCounter () {
      return this.currentInfra.length
    },
    getInfraIcon (type) {
      return type === 'instances'
        ? 'Compute_AmazonEC2'
        : null
    },
    async getAwsCredentials () {
      this.loading.credentials = true
      if (!this.hasNoAWSCredentials && this.canListCredentials) {
        await this.FETCH_AVAILABLE({ keyPath: 'credentials' })
      }
      this.credentialCanonical = _.get(this.getCredentialsByType('aws'), '[0].canonical', null)
      this.loading.credentials = false
    },
    async getAggregatedInfra () {
      this.loading.aggregatedInfra = true
      const { credentialCanonical, $static: { infraTypes, defaultTags } } = this
      await this.GET_RUNNING_INFRA({ credentialCanonical, infraTypes, defaultTags })

      if (this.runningInfra) {
        this.aggregatedInfra = this.runningInfra
        for (const type of infraTypes) {
          if (_.get(this.aggregatedInfra, type, {}).total_amount >= 0) {
            this.selectedInfraType = type
            break
          }
        }
        this.loading.aggregatedInfra = false
        return
      }

      this.aggregatedInfra = []
      this.selectedInfraType = null
      this.loading.aggregatedInfra = false
    },
    async getCurrentInfra () {
      this.loading.currentInfra = true
      if (this.selectedInfraType === 'instances') {
        const { credentialCanonical, $static: { defaultTags } } = this
        await this.GET_RUNNING_INSTANCES({ credentialCanonical, defaultTags })
        this.currentInfra = this.runningInstances || {}
      }
      this.totalItems = this.currentInfra.length
      this.loading.currentInfra = false
    },
    containedInProperty (itemFromAPI) {
      const { search, selectedFields } = this
      return selectedFields.filter((selectedField) => {
        const val = _.get(itemFromAPI, selectedField)
        const matchesSearch = (str) => str.toLowerCase().includes(search.toLowerCase())

        if (_.isString(val)) return matchesSearch(val)
        if (_.isPlainObject(val)) return _.some(Object.values(val), matchesSearch)
        if (_.isArray(val)) {
          return val.some((arrayItem) => {
            if (_.isString(arrayItem)) return matchesSearch(arrayItem)
            if (_.isPlainObject(arrayItem)) return _.some(Object.values(arrayItem), matchesSearch)
            return false
          })
        }
        return false
      })
    },
  },
  i18n: {
    messages: {
      en: {
        header: '@:title',
        instances: 'Instances',
        cantListCredentials: 'You do not have the required permissions to view your infrastructure.',
        noAwsCredentials: 'You need to have an AWS credential to be able to view your infrastructure.',
        noAwsResourcesForCredential: 'No resource found for this credential.',
        noDataFound: 'No data found',
        title: 'Infrastructure',
      },
      es: {
        header: '@:title',
        instances: 'Instancias',
        cantListCredentials: 'No tiene los permisos necesarios para ver su infraestructura.',
        noAwsCredentials: 'Debes tener una credencial AWS para poder ver tu infraestructura.',
        noAwsResourcesForCredential: 'Ningún recurso encontrado para esta credencial.',
        noDataFound: 'Ninguna información encontrada',
        title: 'Infraestructura',
      },
      fr: {
        header: '@:title',
        instances: 'Instances',
        cantListCredentials: `Vous n'avez pas les autorisations nécessaires pour visualiser votre infrastructure.`,
        noAwsCredentials: `Vous devez disposer d'un identifiant AWS pour pouvoir voir votre infrastructure.`,
        noAwsResourcesForCredential: `Aucune ressource trouvée pour ces informations d'identification.`,
        noDataFound: 'Aucune donnée trouvée',
        title: 'Infrastructure',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  $header-height: 70px;

  ::v-deep .v-list-item {
    padding-right: 16px;
    padding-left: 16px;
  }

  .content-header {
    display: flex;
    flex-direction: row;
    height: $header-height;
  }

  .content-body {
    display: flex;
    flex-direction: row;
    height: calc(100% - #{$header-height});

    &__inner {
      height: 100%;

      .instances-list {
        height: 100%;

        @extend %cy-scrollbars-always-show;
      }
    }
  }

  .infra-type {
    margin-top: -1px;
    border: 1px solid cy-get-color("grey");
    border-width: 1px 4px;
    border-style: solid;
    border-color: cy-get-color("grey") transparent;
  }

  .selected-infra {
    border-width: 1px 4px;
    border-style: solid;
    border-right-color: cy-get-color("accent");
    border-left-color: cy-get-color("accent");

    .list__tile__content {
      font-weight: bold;
    }
  }

  .search {
    margin-top: 2px;
    border-radius: 5px;
    box-shadow: none !important;

    .v-btn {
      margin-right: 0;
      float: right;
    }
  }

  .infra-type-list {
    box-shadow: none !important;

    &__item {
      padding: 0;
    }
  }

  .settings {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: inherit !important;
    margin-top: 5px;
    background: white;
    box-shadow: cy-get-box-shadow(light);

    ::v-deep .v-icon--checkbox {
      font-size: 18px;
    }

    .v-input--checkbox {
      margin-top: 0;
    }

    .v-input--checkbox ::v-deep .v-icon {
      font-size: 18px;
    }

    .v-input--checkbox ::v-deep .v-label {
      font-size: 0.85em;
    }
  }

  .credentials {
    height: 64px;
    margin-top: 6px;

    ::v-deep .input-group__input {
      padding-top: 8px;
    }
  }

  .blur {
    filter: blur(3px);
  }

  .fade-height-enter-active,
  .fade-height-leave-active {
    max-height: 230px;
    transition: all 0.2s;
  }

  .fade-height-enter,
  .fade-height-leave-to {
    max-height: 0;
    opacity: 0;
  }

  .dataset__checkbox ::v-deep .v-input--selection-controls__input {
    margin-right: 0;
  }

  .env-selection {
    position: absolute;
    bottom: 1em;
    left: 2em;
  }
</style>
