<template>
  <ul class="element-property element-property--list">
    <li
      v-for="(value, index) of listProperty"
      :key="index">
      <CyInfraViewProperty
        :property="{ value }"
        :class="['list-item', { 'mb-0': _.$isPrimitive(value) }]"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CyInfraViewListProperty',
  components: {
    CyInfraViewProperty: () => import('@/components/infra-view/property.vue'),
  },
  props: {
    listProperty: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .element-property {
    &--list {
      flex-direction: column;
      padding-left: 0;
      list-style: none;
    }

    &.list-item {
      ::v-deep .copy-value-btn {
        bottom: 0;
      }
    }
  }
</style>
