var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-panel"},[_c('div',{staticClass:"right-panel-header"},[_c('div',{staticClass:"right-panel-header__element-info element-info"},[_c('img',{staticClass:"element-info__icon",attrs:{"src":_vm.element._image,"alt":"Logo"}}),_c('div',{staticClass:"element-info__canonical-info canonical-info"},[_c('CyTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var nameTooltip = ref.on;
return [_c('h3',_vm._g({staticClass:"canonical-info__name"},nameTooltip),[_vm._v(" "+_vm._s(_vm.nodeName || _vm.element._name)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.nodeName || _vm.element._name))])]),_c('CyInfraViewResourceType',{attrs:{"resource":_vm.element}})],1)]),_c('div',{staticClass:"right-panel-header__actions actions"},[_c('CyButton',{staticClass:"actions__btn actions__docs",attrs:{"icon-only":"","theme":"primary","variant":"tertiary","icon":"help_outline","sm":""},on:{"click":_vm.openDocPage}}),_c('CyButton',{staticClass:"actions__btn actions__center",attrs:{"icon-only":"","theme":"primary","variant":"tertiary","icon":"my_location","sm":""},on:{"click":function($event){return _vm.$emit('center-element')}}}),_c('v-icon',{staticClass:"actions__btn actions__close",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('close-right-panel')}}},[_vm._v(" close ")])],1)]),_c('div',{class:['right-panel-description', {
      'right-panel-description--expanded': _vm.isDescriptionExpanded,
    }]},[(_vm.shortDescriptionLength >= 85)?[(_vm.isDescriptionExpanded)?_c('span',[_vm._v(_vm._s(_vm.element._shortDescription))]):_c('span',[_vm._v(_vm._s(_vm._.truncate(_vm.element._shortDescription, { length: 85 })))]),(!_vm.isDescriptionExpanded)?_c('button',{staticClass:"description__reveal",on:{"click":function($event){_vm.isDescriptionExpanded = true}}},[_vm._v(" "+_vm._s(_vm.$t('seeMore'))+" ")]):_c('button',{staticClass:"description__collapse",on:{"click":function($event){_vm.isDescriptionExpanded = false}}},[_vm._v(" "+_vm._s(_vm.$t('collapse'))+" ")])]:[_vm._v(" "+_vm._s(_vm.element._shortDescription)+" ")]],2),_c('div',{staticClass:"right-panel-content"},[(_vm.element._canAddTags)?_c('div',{staticClass:"right-panel-content__tags"},[_c('CyInfraViewRightPanelTags',{attrs:{"entity":_vm.element,"readonly":""}})],1):_vm._e(),_c('div',{staticClass:"right-panel-content__search"},[_c('v-text-field',{attrs:{"append-icon":"search","placeholder":"Search","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"right-panel-content__properties"},_vm._l((_vm._.entries(_vm.elementProperties)),function(ref){
    var key = ref[0];
    var value = ref[1];
return _c('CyInfraViewProperty',{key:key,staticClass:"element-property",attrs:{"property":{ key: key, value: value }}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }