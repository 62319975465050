var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource-instance d-flex py-6 px-4"},[_c('div',{staticClass:"resource-instance__icon-area text-center"},[_c('img',{staticClass:"resource-icon",attrs:{"src":_vm.resource._image}}),(_vm.status)?_c('v-chip',{class:['resource-status', {
        'v-chip--success': _vm.status === 'running',
        'v-chip--warning': _vm.status === 'pending',
        'v-chip--error': _vm.status === 'stopping',
        'v-chip--default': _vm.status === 'unknown',
      }],attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm._.upperFirst(_vm.status))+" ")]):_vm._e()],1),_c('div',{staticClass:"resource-instance__details-area resource-details mx-2"},[_c('div',{staticClass:"resource-details__row pb-2"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"resource-details__name-type d-flex flex-column"},[_c('span',{staticClass:"h5"},[_vm._v(" "+_vm._s(_vm.resource._name)+" ")]),_c('CyInfraViewResourceType',{attrs:{"resource":_vm.resource,"bottom":"","only-show-for-modules":""}})],1),_c('span',{staticClass:"font-weight-bold text-uppercase"},[(_vm.region)?_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(" place ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.region)+" ")],1),_c('div',{staticClass:"resource-details__refresh-instance d-flex align-start justify-end"},[(false)?_c('div',[(_vm.refreshing)?_c('span',{staticClass:"refresh-text mr-2"},[_vm._v(" "+_vm._s(_vm.$t('refreshing'))+" ")]):_vm._e(),_c('CyTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_c('CyButton',{attrs:{"icon":"refresh","icon-only":"","theme":"secondary","variant":"tertiary","sm":""}})],1)]}}],null,false,696709224)},[_vm._v(" "+_vm._s(_vm.$t('refreshResourceInstance', { resourceName: _vm.resource._name }))+" ")])],1):_vm._e()])])]),_c('div',{staticClass:"resource-details__row py-2"},[_c('v-expansion-panels',{model:{value:(_vm.showConfiguration),callback:function ($$v) {_vm.showConfiguration=$$v},expression:"showConfiguration"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-0"},[_c('div',{staticClass:"resource-details__property font-weight-bold pr-4"},[_vm._v(" "+_vm._s(_vm._.isUndefined(_vm.showConfiguration) ? _vm.$t('seeWholeConfiguration') : _vm.$t('hideWholeConfiguration'))+" ")])]),_c('v-expansion-panel-content',{staticClass:"pa-0"},_vm._l((_vm.propertiesToDisplay),function(ref){
      var key = ref[0];
      var value = ref[1];
return _c('CyInfraViewProperty',{key:key,attrs:{"property":{ key: key, value: value }}})}),1)],1)],1)],1),_c('div',{staticClass:"resource-details__row pt-2"},[(!_vm._.isEmpty(_vm.tags))?_c('CyTagList',{attrs:{"tags":_vm.tags,"contained":"","variant":"default","small":""}}):_c('span',{staticClass:"no-tags-text"},[_vm._v(" "+_vm._s(_vm.$t('noTagsDefined'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }